import React from 'react';
import { useParams } from 'react-router-dom'
import { blogPosts } from '../copy'

const Project = () => {
  const { projectName } = useParams();
  const { content } = blogPosts[projectName];
  return (
    <section className="project">
      {content}
    </section>
  );
}
 
export default Project;