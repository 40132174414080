import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header>

      <h1><Link to="/">Andrew Lowell Richardson</Link></h1>

      <nav>
        <input type="checkbox" id="nav" className="hidden" />
        <label htmlFor="nav" className="nav-open">
          <i></i>
          <i></i>
          <i></i>
        </label>

        <div className="nav-container">
          <ul>
            {/* <li><NavLink to="/" className="active">Home</NavLink></li> */}
            <li><Link to="/projects" className="active">Projects <i class="fas fa-laptop-code fa-2x"></i></Link></li>
            <li><a href="https://github.com/andrewlowell" className="active">GitHub <i className="fab fa-github-square fa-2x"></i></a></li>
            <li><a href="https://www.linkedin.com/in/andrew-lowell/" className="active">LinkedIn <i className="fab fa-linkedin fa-2x"></i></a></li>
            {/* <li><a href="about.html">Bio &amp; Contact</a></li> */}
          </ul>
        </div>
      </nav>

    </header>
  );
}
 
export default Header;