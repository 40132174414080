import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'
import Header from './components/Header'
import Home from './components/Home'
import Project from './components/Project'
import Projects from './components/Projects'
import Footer from "./components/Footer"
import './App.css';

function App() {
  return (
    <div className="App">

      <Router>
    
        <ScrollToTop />

        <div id="container">

          <Header />

          <Route exact path="/" component={Home} />
          <Route exact path="/projects" component={Projects} />
          <Route path="/projects/:projectName" component={Project} />

          <Footer />

        </div>

      </Router>

    </div>
  );
}

export default App;
