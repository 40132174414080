import React from 'react';
import { Link } from 'react-router-dom';

const Card = ({ photo, project: { image, link, title, url, description, tech }}) => {
  return (
    <li className="cards_item">
      <Link to={`/projects/${url}`}>
        <div className="card">

          <div className="card_image">
            <div className="img-container">
              <img src={photo} alt="placeholder" />
            </div>
          </div>

          <div className="card_content">
            <h2 className="card_heading">{title}</h2>
            <p>{description}</p>
            <span className="card__pills">{tech}</span>
          </div>

        </div>
      </Link>
    </li>
  );
}
 
export default Card;