import React from 'react'
import { Link } from 'react-router-dom'
import resume from '../images/Andrew-Lowell-Richardson-Resume-Download.pdf'

const About = () => {
  return (
    <section className="about">

      <h2>About Me</h2>
      <p>
        Hi, I’m Lowell Richardson! (I go by my middle name.) In an ideal world, we could network in person, but I’m happy you stopped by to become acquainted with my professional work.
        I am a full-stack web developer with a particular interest in structural design on both front and back end. My specialties include Javascript, React, Node, Express, and Python.
        Over the past eight years, my experience in customer service and academic support has helped me develop the ability to communicate clearly, resolve conflicts, and contribute positively to a team.
        I also have significant teaching and mentoring experience. I am always interested in opportunities that will allow me to develop my programming skills and eventually grow into a role where I can mentor junior developers.
        If I’m not programming, you might find me hosting a dinner party for friends or practicing Vulfpeck or Red Hot Chili Peppers songs on my electric bass.
      </p>
      <br />
      <p>Professional Skills: HTML, CSS, JavaScript, React.js, Node.js, Express.js, Python</p>
      <br />
      <p>Check out some of my <Link to="/projects">projects</Link>, download my <a href={resume}>resume</a>, or feel free to reach out on <a href="https://www.linkedin.com/in/andrew-lowell/">LinkedIn</a>!</p>
      
    </section>
  );
}
 
export default About;