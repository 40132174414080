import React from 'react';
import About from './About'
import alr from '../images/andrewlowellrichardson.jpg';

const Home = () => {
  return (
    <>
      <section className="homepage">

        <img src={alr} alt="andrewlowellrichardson" />
        <h1>Andrew Lowell Richardson</h1>
        <h2>Full-Stack Developer</h2>
        <p>with a passion for community building,</p>
        <p>clear communication, and performant code.</p>

      </section>

      <About />
      {/* <Projects /> */}

    </>
  );
}
 
export default Home;