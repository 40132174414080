import React from 'react'
import Card from "./Card"
import { project1, project2, project3 } from "../copy"
import photo1 from '../images/hirelambda.jpg'
import photo2 from '../images/picasso.png'
import photo3 from '../images/spotifinder.jpg'

const Projects = () => {
  return (
    <section className="projects">

      <h2>Projects</h2>

      <p>Click through for links to each deployed site and GitHub repo.</p>

      <ul className="cards">

        <Card project={project1} photo={photo1} />
        <Card project={project2} photo={photo2} />
        <Card project={project3} photo={photo3} />

      </ul>
    </section>
  );
}
 
export default Projects;