import React from 'react';

const Footer = () => {
  return (
    <footer>

      <p className="foot">&copy; {new Date().getFullYear()}, Andrew Lowell Richardson</p>

      <nav>
          {/* <li><a href="#"> email</a></li>
          <li><a href="#" target="_blank"> instagram</a></li> */}
          {/* <li><a href="#" target="_blank"> twitter</a></li>
          <li><a href="#" target="_blank"> linkedin</a></li>
          <li><a href="#" target="_blank"> twitter</a></li> */}
      </nav>

    </footer>
  );
}
 
export default Footer;