import React from 'react';

import photo1 from '../images/hirelambda2.jpg'
import photo2 from '../images/picasso.png'
import photo3 from '../images/spotifinder.jpg'
import photo4 from '../images/spotifinderDashboard.png'
import photo5 from '../images/picassoGallery.png'
import photo6 from '../images/hireLambdaSearch.png'

export const project1 = {
  image: "hirelambda.jpg",
  link: "https://hirelambdastudents.com",
  title: "Hire Lambda Students",
  url: "hirelambdastudents",
  description: "A secure platform that allows students to showcase their most impressive projects and coding skills. Hiring managers search the site by location and skill to find perfect interview candidates for their companies' needs.",
  tech: "React, Node, Express, PostgreSQL, Auth0"
}

export const project2 = {
  image: "picasso.png",
  link: "https://picasso-frontend.netlify.com/",
  title: "Picasso Painted It",
  url: "picassopaintedit",
  description: "An engaging website that transforms user-uploaded images into the style of selected Picasso paintings using neural style transfer. Displays modified images on a public gallery page.",
  tech: "DeepAI API, React, Node, Cloudinary"
}

export const project3 = {
  image: "spotifinder.jpg",
  link: "https://spotifinder.ml",
  title: "Spotifinder",
  url: "spotifinder",
  description: "A recommendation engine that helps users discover music from a Kaggle dataset of Spotify songs. The application employs a KDTree algorithm to process audio features of a user-selected song and produce a list of 10 highly similar tracks.",
  tech: "React, Node, Express, PostgreSQL"
}

export const blogPosts = {
  spotifinder: {
    content: <>
      <h2>Spotifinder</h2>
      <div className="projectLinkContainer">
        <a className="tryIt" href="https://spotifinder.ml">Deployed Site</a>
        <a className="gitHubLinks" href="https://github.com/bw-spotify/frontend"><i className="fab fa-github fa-2x"></i> Front End Repository</a>
      </div>
      <img src={photo3} alt=""/>
      <p>Spotifinder is a recommendation engine that helps users discover music from a Kaggle dataset of Spotify songs.
          The data scientists on our team employed a KDTree algorithm to process audio features associated with each song
          in the data set including danceability, acousticness, energy, liveness, and speechiness.
          The algorithm produced a list of 10 tracks for each song which were highly similar, meaning that their distribution of attributes corresponded closely one with another.
          The web interface allows users to search the data set for specific songs and then displays the song attributes and links to the ten related songs so the user can continue to explore and find fun new music! 
          There's also a Spotify preview widget which allows users to listen to the songs right on the site.</p>
      <img src={photo4} alt="Spotifinder Dashboard"/>
      <p>We used a library to display a graph of the various acoustic properties of each song, and it's fun to see how similar the suggested songs are to one another. Some fall in the same genre and some are wildly different, but the feeling is still much the same for all the suggested songs. What a cool way to use technology!</p>
    </>,
    url: "https://spotifinder.ml",
  },
  picassopaintedit: {
    content: <>
      <h2>Picasso Painted It</h2>
      <div className="projectLinkContainer">
        <a className="tryIt" href="https://picasso-frontend.netlify.com/">Deployed Site</a>
        <a className="gitHubLinks" href="https://github.com/buildweekpicasso/backend"><i className="fab fa-github fa-2x"></i> Back End Repository</a>
      </div>
      <img src={photo2} alt=""/>
      <p>
        Picasso Painted it is an engaging website that transforms user-uploaded images into the style of selected Picasso paintings using neural style transfer.
        The modified images are collected and displayed on a public gallery page so users can get a little inspiration.
      </p>
      <img src={photo5} alt=""/>
      <p>
        The data scientists on our team worked on training a neural style transfer model and created a Flask application which used the model to process images.
        I worked with a partner on the backend Node.js application. We wrote API endpoints to accept images from the React.js frontend to store uploaded images in Cloudinary using their excellent API.
        If I could do one thing differently, I would have figured out how to save the user-uploaded pictures as binary blobs into a database or used a third-party storage system of some kind.
        We realized halfway through the project that saving the pictures in the filesystem wouldn't work because Heroku flushes the filesystem each day. It's high on my list of weekend projects to dive back in and make that fix so those images aren't broken for the many (unexpected!) users who still use the site.
      </p>
    </>,
    url: "https://picasso-frontend.netlify.com/"
  },
  hirelambdastudents: {
      content: <>
      <h2>Hire Lambda Students</h2>
      <div className="projectLinkContainer">
        <a className="tryIt" href="https://hirelambdastudents.com">Deployed Site</a>
        <a className="gitHubLinks" href="https://github.com/labs12-project-showcase/labs12-project-showcase-FE"><i className="fab fa-github fa-2x"></i> Front End Repository</a>
        <a className="gitHubLinks" href="https://github.com/labs12-project-showcase/labs12-project-showcase-BE"><i className="fab fa-github fa-2x"></i> Back End Repository</a>
      </div>
      <img src={photo1} alt=""/>
      <p>
        The Hire Lambda Students project came about as an idea I had with a group of peers in Lambda School's web curriculum.
        At the time, senior staff members would periodically tweet about qualified students and invite their followers to hire them.
        We wanted to provide ourselves and our peers with a more scalable solution, and conceived of a website where students could fill out a profile with their skills and information, aggregating Lambda talent in one place.
        Hiring managers can search for local candidates with their preferred skills. I collaborated with five other students to write front and backend code for the site's login and search functionality.
      </p>
      <img src={photo6} alt=""/>
      <p>
        Eventually Lambda School came up with their own internal solutions to get students hired, but I still think if LS becomes reliable and prestigious enough, a student social/professional network like hirelambdastudents.com might come in handy!
      </p>  
    </>,
    url: "https://hirelambdastudents.com",
  },
}